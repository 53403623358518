import Config from './Config';
import UserAgentDetector from '../utils/UserAgentDetector';
import VirtualKeyboardManager from './VirtualKeyboardManager';

export default new class ScaleManager {
  constructor() {
    window.addEventListener('visibilitychange', this.requestWakeLock.bind(this));
    this.requestWakeLock();

    this._defaultFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    this._currentFontSize = this._defaultFontSize;
    document.documentElement.style.setProperty(`--default-font-size`, this._defaultFontSize);
    document.documentElement.style.setProperty(`--font-size`, this._currentFontSize);

    this.onResize = this.onResize.bind(this);

    this._isResizeListenersAttached = false;
    this._canvasUpdatedInitially = false;
    this.initialized = false;
  }

  preInit(data, config) { //Init without canvas
    this.init(data, config, true);
  }

  init(data, config, isPreInit) {
    if (this.initialized) return;
    this.initialized = !isPreInit;
    this.config = config ? { ...Config, ...config } : Config;
    this.currentSizes = null;
    this._currentData = null;
    this.gameContainer = window.OPWrapperService.initConfig.gameContainer;

    this.data = data || null;
    this.addListeners();
    this.onResize(true);
  }

  addListeners() {
    if (this._isResizeListenersAttached) return;
    this._isResizeListenersAttached = true;

    visualViewport.addEventListener('resize', this.onResize);

    document.addEventListener('fullscreenchange', this.onResize);
    /* Firefox */
    document.addEventListener('mozfullscreenchange', this.onResize);
    /* Chrome, Safari and Opera */
    document.addEventListener('webkitfullscreenchange', this.onResize);
    /* IE / Edge */
    document.addEventListener('msfullscreenchange', this.onResize);
  }

  makeScalingHTMLElement({
                           HTMLElement,
                           relativeScale, //0 - 1
                           // not required
                           defaultWidth, //px
                           defaultHeight, //px
                           forceLeftMargin, //px
                           forceTopMargin, //px
                         }) {
    // let leftMargin,
    //   leftCorrectionForScale,
    //   topMargin,
    //   topCorrectionForScale
    //
    // if (defaultWidth) {
    //   const innerWidth = this.currentData ? this.currentData.innerWidth : visualViewport.width;
    //   leftMargin = typeof forceLeftMargin === 'number' ? forceLeftMargin : (innerWidth - defaultWidth * relativeScale) / 2
    //   leftCorrectionForScale = (defaultWidth - defaultWidth * relativeScale) / 2
    // }
    // if (defaultHeight) {
    //   const innerHeight = this.currentData ? this.currentData.innerHeight : visualViewport.height;
    //   topMargin = typeof forceTopMargin === 'number' ? forceTopMargin : this.config && this.config.BIND_TO_TOP ? 0 : (innerHeight - defaultHeight * relativeScale) / 2
    //   topCorrectionForScale = (defaultHeight - defaultHeight * relativeScale) / 2
    // }
    //
    // Object.assign(HTMLElement.style, {
    //   transform: `scale(${relativeScale}) translateZ(0)`,
    //   width: defaultWidth ? `${defaultWidth}px` : undefined,
    //   height: defaultHeight ? `${defaultHeight}px` : undefined,
    //   left: defaultWidth ? `${leftMargin - leftCorrectionForScale}px` : undefined,
    //   top: defaultHeight ? `${topMargin - topCorrectionForScale}px` : undefined,
    // });
  }

  onResize(isForce) {
    console.log('Is virtual keyboard affects layout: ', VirtualKeyboardManager.isVirtualKeyboardAffectsLayout);

    let newInnerWidth = visualViewport.width;
    let newInnerHeight = visualViewport.height;

    if (
      (this.innerWidth === newInnerWidth
        && this.innerHeight === newInnerHeight
        && isForce !== true
      ) ||
      (this._canvasUpdatedInitially && VirtualKeyboardManager.isVirtualKeyboardAffectsLayout)
      // (this._canvasUpdatedInitially && Fullscreen.isDrag)
    ) return;

    this.innerWidth = newInnerWidth;
    this.innerHeight = newInnerHeight;

    this.checkOrientation();
    this.calculateCurrentSize();

    const scaleRatio = (this.data && this.data.scaleRatio) || 1; //UserAgentDetector.isMobile.any ? 2 : 1;

    const finalInnerWidth = this.innerWidth * scaleRatio;
    const finalInnerHeight = this.innerHeight * scaleRatio;

    this._currentScale = Math.min(finalInnerWidth / this.safeZone.width, finalInnerHeight / this.safeZone.height);
    this._currentFontSize = this._defaultFontSize * this._currentScale;
    document.documentElement.style.fontSize = this._currentFontSize + 'px';
    document.documentElement.style.setProperty(`--font-size`, this._currentFontSize);

    this._currentData = {
      orientation: this.orientation,
      isPortrait: this.orientation === 'V',
      isLandscape: this.orientation === 'H',

      gameWidth: this.currentSizes.width,
      gameHeight: this.currentSizes.height,
      stageX: (finalInnerWidth - (this.safeZone.width * this._currentScale)) / 2 - (this.safeZone.left) * this._currentScale,
      stageY: this.config.BIND_TO_TOP ? 0 : (finalInnerHeight - (this.safeZone.height * this._currentScale)) / 2 - this.safeZone.top * this._currentScale,

      isMobile: UserAgentDetector.isMobile.any,
      scaleWidth: finalInnerWidth / this._currentScale / this.currentSizes.width,
      scaleHeight: finalInnerHeight / this._currentScale / this.currentSizes.height,
      innerWidth: this.innerWidth,
      innerHeight: this.innerHeight,
      finalInnerWidth: finalInnerWidth,
      finalInnerHeight: finalInnerHeight,
      currentFontSize: this._currentFontSize,
      scale: this._currentScale,
    };

    if (this.initialized) this.updateCanvas();
    console.log('SCALE MANAGER RESIZE')
    this.emitResize();
  }

  updateCanvas() {
    this._canvasUpdatedInitially = true;
    this.data.app.view.style.width = `${this.currentData.innerWidth}px`;
    this.data.app.view.style.height = `${this.currentData.innerHeight}px`;
    this.data.app.renderer.resize(this.currentData.finalInnerWidth, this.currentData.finalInnerHeight);
    this.data.app.stage.scale.set(this.currentData.scale, this.currentData.scale);
    this.data.app.stage.x = this.currentData.stageX;
    this.data.app.stage.y = this.currentData.stageY

    this._currentData = {
      ...this._currentData,
      canvasWidth: this.data.app.view.width,
      canvasHeight: this.data.app.view.height,
      stageHeight: this.data.app.stage.height,
      stageWidth: this.data.app.stage.width,
    }
  }

  emitResize() {
    window.OPWrapperService.eventManager.dispatch(window.OPWrapperService.eventManager.types.EET_SCALE_MANAGER_RESIZE, this.currentData);
  }

  checkOrientation() {
    if (this.config.FORCE_PORTRAIT || this.innerWidth < this.innerHeight && UserAgentDetector.isMobile.any) {
      this.orientation = 'V';
      this.shiftX = (this.config.CANVAS_WIDTH_PORTRAIT - this.config.SAFE_WIDTH_PORTRAIT) / 2;
      this.shiftY = this.config.BIND_TO_TOP ? 0 : (this.config.CANVAS_HEIGHT_PORTRAIT - this.config.SAFE_HEIGHT_PORTRAIT) / 2;
      this.safeZone = {
        left: this.shiftX,
        top: this.shiftY,
        width: this.config.SAFE_WIDTH_PORTRAIT,
        height: this.config.SAFE_HEIGHT_PORTRAIT
      };
      this.gameRatio = this.config.CANVAS_WIDTH_PORTRAIT / this.config.CANVAS_HEIGHT_PORTRAIT;
      window.OPWrapperService.ControllerStatistic.setOrientation('portrait');
    } else {
      this.orientation = 'H';
      this.shiftX = (this.config.CANVAS_WIDTH_LANDSCAPE - this.config.SAFE_WIDTH_LANDSCAPE) / 2;
      this.shiftY = this.config.BIND_TO_TOP ? 0 : (this.config.CANVAS_HEIGHT_LANDSCAPE - this.config.SAFE_HEIGHT_LANDSCAPE) / 2;
      this.safeZone = {
        left: this.shiftX,
        top: this.shiftY,
        width: this.config.SAFE_WIDTH_LANDSCAPE,
        height: this.config.SAFE_HEIGHT_LANDSCAPE
      };
      this.gameRatio = this.config.CANVAS_WIDTH_LANDSCAPE / this.config.CANVAS_HEIGHT_LANDSCAPE;
      window.OPWrapperService.ControllerStatistic.setOrientation('landscape');
    }
  }

  calculateCurrentSize() {
    let windowRatio = this.innerWidth / this.innerHeight;
    this.currentSizes = UserAgentDetector.isMobile.any ? (windowRatio >= 1 ? this.data.gameSize.mobileLandscape : this.data.gameSize.mobilePortrait) : this.data.gameSize.desktop;
    if (!this.currentSizes) {
      this.currentSizes = this.data.gameSize;
    }
  }

  async requestWakeLock() {
    if (document.visibilityState === 'visible' && 'wakeLock' in navigator) {
      try {
        await navigator.wakeLock.request('screen');
      } catch (e) {

      }
    }
  }

  convertPxToRem(px) {
    return px / this._defaultFontSize;
  }

  get currentData() {
    return this._currentData;
  }

  get defaultFontSize() {
    return this._defaultFontSize;
  }

  get currentFontSize() {
    return this._currentFontSize;
  }

  get currentScale() {
    return this._currentScale;
  }

  get currentScaleCoefficient() {
    return this._defaultFontSize / this._currentFontSize;
  }
}
