import BasePlayerUi from '../player/basePlayerUI';
import logoIcon from '../../../assets/images/onlyplay_logo_white_icon.svg'

export default class PortraitUi extends BasePlayerUi {
  constructor({ config, partnerConfig }) {
    super({ config, partnerConfig });

    this._config = {
      ...this._config,
      offsets: {
        top: 0,
        left: 0,

        tournaments_panel: {
          top: 0,
          left: 0,
        }
      },
      ui_container: {
        padding: 0,
        backgroundColor: 'transparent',
      },
    };

    if (config) {
      this._config.offsets = { ...this._config.offsets, ...config.offsets };
      this._config.ui_container = { ...this._config.ui_container, ...config.ui_container };
    }

    this.init();
  }

  init(container) {
    super.init(container);
    this.root.setAttribute('data-orientation', 'portrait');
  }

  _getAutoplayButtonMarkup() {
    return this._excludedControllerTypes.has(this.controllerTypes.ECT_AUTO_SPIN) ?
      '' :
      `<div id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_SPIN]}></div>`
  }

  _getTurboPlayButtonMarkup() {
    return this._excludedControllerTypes.has(this.controllerTypes.ECT_TURBO_SPIN) ?
      '' :
      `<div id=${this.controllersContainersIds[this.controllerTypes.ECT_TURBO_SPIN]}></div>`
  }

  _getConstantMarkup() {
    return `<div class="ui-container__tournaments_label_container" data-orientation="portrait" style="top: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.tournaments_panel.top)}rem; left: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.tournaments_panel.left)}rem">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_TOURNAMENTS_LABEL]}>
      </div>
    </div>`
  }

  _getMarkup() {
    return `
    ${this._getConstantMarkup()}

        <!--            modals-->
    <div class="ui-container__bet_selector_container" data-orientation="portrait" style="padding-bottom: ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.top)}rem">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECTOR_TABLE]}>
      </div>
    </div>
    <div class="ui-container__auto_play_table" data-orientation="portrait" style="padding-bottom: ${window.OPWrapperService.ScaleManager.convertPxToRem(-this._config.offsets.top)}rem">
      <div id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_PLAY_TABLE]}>
      </div>
    </div>
        
    <!--    panels    -->
    <div class="ui-container__info_panel ui-container__info_panel__portrait">
    <div class="ui-container__info_panel__container" data-orientation="portrait" data-device-type="mobile">
      <div class="ui-container__info_panel__left">
        <img class="ui-container__info_panel__logo" src="${logoIcon}" alt="logo">
        </div>
        <div class="ui-container__info_panel__right">
        <!-- clock -->
          <div class="ui-container__info_panel__list" id="${this.controllersContainersIds[this.controllerTypes.ECT_INFO_PANEL]}"></div>
        </div>
      </div>
    </div>
    
        <!--    panels    -->
    <div class="ui-container__info_panel ui-container__info_panel__portrait ui-container__info_panel__bottom">
    <div class="ui-container__info_panel__container">
      <div class="ui-container__info_panel__left">
        <div class="ui-container__info_panel__list">
          <span>Onlyplay</span>
          <span>${document.title}</span>
        </div>
      </div>
      <div class="ui-container__info_panel__right">
        <!--    sound    -->
        <div class="ui-container__controller_sound" id=${this.controllersContainersIds[this.controllerTypes.ECT_SOUND]}>
        </div>
        <!-- clock -->
        <div id="${this.controllersContainersIds[this.controllerTypes.ECT_INFO_PANEL_CLOCK]}"></div>            
      </div>
      </div>
    </div>

    <div class="ui-container__portrait">
      <div class="ui-container__portrait__container" style="background-color: ${this._config.ui_container.backgroundColor}; padding: ${this._config.ui_container.padding};  top: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.top)}rem; left: ${window.OPWrapperService.ScaleManager.convertPxToRem(this._config.offsets.left)}rem">
      
        <div class="ui-container__portrait__container__top_block">
          <!--      win / last win     -->
          <div class="top_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_WIN]}>
          </div>
          <!--      label     -->
          <div class="top_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_LABEL]}>
          </div>
          <!--     total win     -->
          <div  class="bottom_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_TOTAL_WIN]}>
          </div>
          <!--     auto spin count     -->
          <div  class="bottom_container" id=${this.controllersContainersIds[this.controllerTypes.ECT_AUTO_SPIN_COUNT]}>
          </div>      
          <!--     win line label     -->
          <div  class="bottom_container ui-container__win_line_label" id=${this.controllersContainersIds[this.controllerTypes.ECT_WIN_LINE_LABEL]}>
          </div>
        </div>
        
        <div class="ui-container__portrait__container__center_block">
          <!--      menu button    -->
          <div class="ui-container__controller_menu" id=${this.controllersContainersIds[this.controllerTypes.ECT_MENU]} data-orientation="landscape">
          </div>
          <!--    turbo play   -->
          ${this._getTurboPlayButtonMarkup()}
          <!--    spin    -->
          <div class="ui-container__controller_spin" id=${this.controllersContainersIds[this.controllerTypes.ECT_SPIN]}>
          </div>
          <!--    autoplay    -->
          ${this._getAutoplayButtonMarkup()}
          <!--      coins button     -->
          <div class="ui-container__controller_bet_selector" id=${this.controllersContainersIds[this.controllerTypes.ECT_BET_SELECT]}>
          </div>
        </div>
        
        <div class="ui-container__portrait__container__bottom_block">
          <!--      info    -->
          <div class="ui-container__controller_info" id=${this.controllersContainersIds[this.controllerTypes.ECT_INFO]}>
          </div>
          <!--      balance     -->
          <div class="ui-container__controller_balance" id=${this.controllersContainersIds[this.controllerTypes.ECT_BALANCE]}>
          </div>
          <!--      bet     -->
          <div class="ui-container__controller_bet" id=${this.controllersContainersIds[this.controllerTypes.ECT_BET]}>
          </div>
        </div>
      </div>
    </div>`;
  }
}
