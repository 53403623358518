import BaseState from 'Engine/base/states/BaseState';
import EntryPoint from 'Engine/EntryPoint';
import SpinTimeManager from 'Engine/base/spinTime/SpinTimeManager';
import ServerManager from '../server/ServerManager';

export default class RequestRollingState extends BaseState {
  constructor(stateData) {
    super(stateData);

  }

  addListeners() {
    super.addListeners();
  }

  async start() {
    SpinTimeManager.start();
    EntryPoint.GameSettings.quickStop = false;
    OPWrapperService.freeBetsController.decreaseFreeBets();
    OPWrapperService.realityCheck.blockedRealityCheck = true;
    OPWrapperService.ControllerStatistic.bet = EntryPoint.GameModel.bet;
    EntryPoint.GameModel.lastBetAmount = EntryPoint.GameModel.bet;
    if (!OPWrapperService.freeBetsController.isActive && !EntryPoint.GameModel.freespinsEnabled) {
      EntryPoint.GameModel.balance = EntryPoint.GameModel.balance - EntryPoint.GameModel.bet;
    }
    super.start();
    await ServerManager.onStartSpin();
    this.complete();
  }

  onEnd() {
    super.onEnd();
  }

  stop() {
    EntryPoint.GameSettings.quickStop = true;
  }
}
